// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}
// ===============================|| CHARTIST ||============================= //
.ct-bar {
    fill: none;
    stroke-width: 23px;
}
// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

.ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}
.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}
.ql-editor {
    min-height: 60px;
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.main-nav {
    display: block;
    width: 80%;
    margin: 0 auto;
    padding: 15px 0 10px;
    text-align: center;
    margin-bottom: 5px;
}
.main-nav .MuiListItemIcon-root {
    min-width: auto !important;
}
.main-nav .MuiListItemText-root {
    display: none;
}
.main-nav svg {
    transform: scale(1.4);
    stroke-width: 1.5px;
}

.MuiDataGrid-virtualScroller {
    // background-color: rgba(106, 106, 137, 0.04);
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    padding-right: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.css-geeono,
.css-xvi2dr {
    margin-bottom: 20px;
    border-radius: 10px;
    min-height: calc(100vh - 92px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.02);
}
.css-1tx5twr-MuiButtonBase-root-MuiListItemButton-root {
    padding: 10px;
}

.css-16lv6j6-MuiTypography-root-MuiLink-root {
    color: #444;
    text-decoration: none;
    font-weight: bold;
}
.css-1rydtoc-MuiDataGrid-columnHeaders {
    color: #000;
}

.MuiChip-root {
    height: auto;
    padding: 1px 0;
}

.MuiDataGrid-cell .MuiButtonBase-root svg {
    width: 20px;
    height: 20px;
    color: #444;
}

@media (max-width: 768px) {
    .MuiBackdrop-root {
        left: 80px;
    }
    .css-geeono {
        margin-top: 86px;
    }
}

.MuiDataGrid-columnHeaders {
    background-color: #f1f1f1;
    // color: #9673b5;
}
.MuiDataGrid-columnSeparator {
    color: #dddddd !important;
}

.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
    display: none;
}
.advance-form-top-menu .MuiTabs-scroller {
    display: flex;
    justify-content: center;
}

.Mui-selected.main-nav {
    background-color: #fafafa;
}
